import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const ControlPanelPage = () => (
  <div>
      <SEO 
        title="Web Control Panel" 
        description="The most easy–to–use Web Control Panel. With the File Manager featured in the Sheephostingbay Web Control Panel, you’ll have one of the most 
        efficient web file management tools in your hands."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5 pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title">The <span className="block text-indigo-600 xl:inline">Sheephostingbay</span> Web Control Panel <span role="img" aria-label="emoji">✨ </span></p>
                <p className="section-after-title">The most easy–to–use Web Control Panel</p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                      <a 
                      href="https://demo.hepsia.com/?auto_login=true" 
                      rel="noreferrer" 
                      target="_blank" 
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                      View Demo
                      </a>
                  </div>
              </div>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <p className="m-0">
                        <img src="https://sheephostingbay.duoservers.com/template2/img/hepsia-banner.webp" alt="Sheephostingbay Web control panel"/>
                    </p>
                  </div>
              </div>
              
            </div>
        </div>
        
    </div>

    

    <div className="py-32 server-bg">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
              <div>
                  <div className="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/file-manager-02.webp" className="w-60" alt="File Manager" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">File Manager </p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Manage your files and folders with a click of the mouse<br/><br/>
                                    With the File Manager featured in the Sheephostingbay Web Control Panel, you’ll have one of the most 
                                    efficient web file management tools in your hands. With it, you can upload files simply by dragging 
                                    them into your browser. You can move files and folders in your account by dragging and dropping 
                                    them. You can uncompress uploaded zipped archives.
                                    <br/><br/>
                                    You can also right–click on a specific file or folder to acquire access to these functions.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/email-manager.webp" className="w-60" alt="Mail Accounts Manager" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Mail Accounts Manager</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    A simple–to–use interface to manage your e–mailbox accounts from<br/><br/>
                                    Whilst composing and sending a mail message is easy, managing your mail accounts can be difficult. The Mail 
                                    Accounts Managerbuilt into the Sheephostingbay Web Control Panel is here to assist you with that. It offers 
                                    you a simplified user interface from where you can easily forward e–mails, create e–mail filters, request 
                                    unsolicited bulk e–mail protection, etc.
                                    <br/><br/>
                                    In case you have an e–mail client, we offer some useful auto–config scripts – just download a file and it 
                                    will set up your electronic mail account instantly. You can also take advantage of our webmail client – 
                                    it’s full–featured and you can access it from any place in the world.
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/a-simple-app-installation.webp" className="w-60" alt="A Online Site Installer" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">A Online Site Installer</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Your new site can now go online immediately<br/><br/>
                                    When you start creating a new site, the faster you launch it online, the better. And picking a web design layout 
                                    can slow you down considerably. With the Online Site Installer built into the Web Control Panel, you can put 
                                    your next site online with a unique website theme in under 5 minutes. All you need to do is just complete 4 
                                    simple steps.
                                    <br/><br/>
                                    Our Online Site Installer comes with more than one hundred free–of–cost web site themes. They’re designed to 
                                    measure up to the requirements of our clients and range from personal weblog and portfolio web site 
                                    templates to corporate themes.
                                    </div>
                                </div>
                            </div>
                        </div>       

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/vpn-access-05.webp" className="w-60" alt="VPN Access" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">VPN Access</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Safe access to the web<br/><br/>
                                    If you need to access a site outside a firewall, or if you want to browse the World Wide Web anonymously, then 
                                    we’ve got the ideal solution for you. It is available in our Web Control Panel. With our VPN access option, 
                                    you’ll be able to browse the Internet safely using your personal computer, notebook or telephone wherever 
                                    you are.
                                    <br/><br/>
                                    You can select the physical location of the VPN server yourself. We have data centers in different cities 
                                    on different continents.
                                    </div>
                                </div>
                            </div>
                        </div>   

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/website-builder.webp" className="w-60" alt="A Complimentary Web Site Building Tool" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">A Complimentary Web Site Building Tool</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Spend less money on web design and development<br/><br/>
                                    Why spend lots of money on employing a costly web site designer when you can do everything on your own in 
                                    precisely the same period of time?
                                    <br/><br/>
                                    In the Web Control Panel you’ll find a fully featured Complimentary Web Site Building Tool. It’s simple to 
                                    use, demands no previous knowledge of CSS, HTML or PHP, comes with one hundred customizable web design 
                                    themes available in different color combinations and includes a fully featured WYSIWYG 
                                    (What–You–See–Is–What–You–Get) editor.
                                    </div>
                                </div>
                            </div>
                        </div>   

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/databases-manager.webp" className="w-60" alt="Databases Manager" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Databases Manager</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    A full array of database management options<br/><br/>
                                    The Databases Manager featured in the Web Control Panel is a fully featured, yet simple–to–use database management 
                                    tool. We’ve streamlined the database setup process – you can create a brand–new database in a couple of seconds. 
                                    We’ve streamlined the database backup procedure – you can back up a database with only a click. The phpPgAdmin 
                                    and phpMyAdmin tools are also just a click away.
                                    <br/><br/>
                                    With our Databases Manager, you will be able to administer all your databases (MySQL or PostgreSQL) from one place.
                                    </div>
                                </div>
                            </div>
                        </div>  

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/modsecurity-03.webp" className="w-60" alt="ModSecurity" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">ModSecurity</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Get a security firewall for your web applications<br/><br/>
                                    In the Sheephostingbay Web Control Panel we have included the ModSecurity Apache module. It’s a small module that 
                                    does a magnificent job – it acts as a firewall for your applications, protecting them against hacker attacks, 
                                    cross–site scripting assaults and URL forgery assaults. We have already specified the rules for the firewall 
                                    program, so you do not need to configure it before it begins working.
                                    <br/><br/>
                                    And ModSecurity is activated by default for all your websites – as soon as they go live, they’ll be instantly 
                                    protected. If you desire, you can manually deactivate ModSecurity for any of your sites.
                                    </div>
                                </div>
                            </div>
                        </div>     

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/web-accelerators.webp" className="w-60" alt="Website Accelerator Tools" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Website Accelerator Tools</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Enhance your online performance with our website accelerators<br/><br/>
                                    The faster your site loads, the better. But at one point, you’ll have to optimize the code. And this is the time 
                                    when you can take full advantage of our selection of Website Accelerator Tools, which consists of Varnish, 
                                    Memcached and Node.js.
                                    <br/><br/>
                                    With their help, you’ll be able to pull a little bit more power out of the server your web site is located on and 
                                    to boost your web site’s load speed enormously.
                                    </div>
                                </div>
                            </div>
                        </div>  
                      
                  </div>
              </div>
          </div>
      </div>
    </div>

    <div className="py-32">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-wrap -mx-2 overflow-hidden">

            <div className="my-2 px-2 sm:w-1/3 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                    <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Web Applications" src="https://sheephostingbay.duoservers.com/template2/img/help-center.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Web Applications</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                            We include a GeoIP re–direction tool, a Sitemap Generator, an RSS News interface and also other 
                            Web Applications, to assist you to market your websites more effectively.
                        </p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/3 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                    <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Online Site Installer" src="https://sheephostingbay.duoservers.com/template2/img/easy-to-install.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Online Site Installer</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        We provide you a great way to begin your brand–new web site online with simply a click of the mouse. 
                        No specialized knowledge are expected on your part. Simply decide on a layout for your website and 
                        let us know what your website address will be. We will do the rest on your behalf.
                        </p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/3 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                    <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Complimentary Web Site Building Tool" src="https://sheephostingbay.duoservers.com/template2/img/24x7-support-img.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Complimentary Web Site Building Tool</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Start your completely new personal or small business web site with simply a mouse click. No site 
                        building abilities demanded. Stick to a few simple steps to create your webpages. Submit images 
                        and video clips with only a mouse click.
                        </p>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default ControlPanelPage
